<template>
  <div class="hold-transition">
    <loading v-if="loading || cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">
                  Editar Programación No.
                  <b>{{ this.$route.params.id }}</b>
                </h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Hidrocarburos</router-link>
                  </li>
                  <li class="breadcrumb-item active">Editar Programación</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="row h-75">
              <div class="col-md-8">
                <form @submit="saveProgramacion()" onsubmit="return false">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for>Nominaciones</label>
                            <v-select
                              :class="[
                                $v.form.nominacion.id.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                              :disabled="form.estado == 2"
                              v-model="form.nominacion"
                              label="nombre"
                              :options="nominacionesOption"
                              placeholder="Nominacion"
                            ></v-select>
                            <router-link
                              v-if="form.nominacion"
                              :to="
                                '/Hidrocarburos/NominacionesEdit/' +
                                  form.nominacion.id
                              "
                            >
                              <button type="button" class="btn btn-link btn-sm">
                                Ver Nominacion
                                <i class="far fa-eye"></i>
                              </button>
                            </router-link>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for>Fecha inicial</label>
                            <input
                              :disabled="form.estado == 2"
                              type="date"
                              class="form-control form-control-sm"
                              v-model="form.fecha_inicial"
                              :class="
                                $v.form.fecha_inicial.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              @input="validaFechas"
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for>Fecha final</label>

                            <input
                              :disabled="form.estado == 2"
                              type="date"
                              class="form-control form-control-sm"
                              v-model="form.fecha_final"
                              :class="
                                $v.form.fecha_final.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              @input="validaFechas"
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for>Estado</label>
                            <select
                              :disabled="
                                form.estado == 2 ||
                                  (form.estado != 2 &&
                                    !$store.getters.can(
                                      'hidrocarburos.programacion.cambiarEstado'
                                    ))
                              "
                              v-model="form.estado"
                              class="form-control form-control-sm"
                              :class="
                                $v.form.estado.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              @change="closeProgramacion"
                            >
                              <option
                                v-for="r in estados"
                                v-bind:key="r.id"
                                v-bind:value="r.numeracion"
                              >
                                {{ r.descripcion }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for>Observación</label>
                            <textarea
                              :disabled="form.estado == 2"
                              v-model="form.observacion"
                              class="form-control form-control-sm"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <div class="row">
                        <router-link to="/Hidrocarburos/Programaciones">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            @click="newItem = true"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.programacion.index'
                              )
                            "
                          >
                            <i class="fas fa-reply"></i>
                            <br />Volver
                          </button>
                        </router-link>

                        <button
                          :disabled="form.estado == 2"
                          type="submit"
                          class="btn btn-primary"
                          v-show="!$v.form.$invalid"
                          v-if="
                            $store.getters.can(
                              'hidrocarburos.programacion.create'
                            ) ||
                              $store.getters.can(
                                'hidrocarburos.programacion.edit'
                              )
                          "
                        >
                          <i class="fas fa-paper-plane"></i>
                          <br />Guardar
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <!-- OPERACIONES ARCHIVO -->
              <div class="col-md-4">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12">
                        <button
                          type="button"
                          class="btn btn-link btn-sm"
                          @click="downloadWithAxios()"
                        >
                          Descargar Plantilla
                          <i class="fas fa-download"></i>
                        </button>
                        <input
                          :disabled="form.estado == 2"
                          type="file"
                          name="fileImport"
                          class="form-control form-control-sm"
                          id="fileImport"
                          ref="fileImport"
                          v-on:change="elegirFile()"
                          accept=".XLSX, .CSV"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4 offset-4" v-if="fileImport">
                        <button
                          :disabled="form.estado == 2"
                          type="button"
                          class="btn bg-navy"
                          @click="importarProgramacion()"
                          v-if="
                            $store.getters.can(
                              'hidrocarburos.programacion.create'
                            ) ||
                              $store.getters.can(
                                'hidrocarburos.programacion.edit'
                              )
                          "
                        >
                          <i class="fas fa-upload"></i>
                          <br />Subir
                        </button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <table
                          class="table table-sm table-striped table-hover"
                          v-if="erroresCargue.length > 0"
                        >
                          <tbody>
                            <tr
                              v-for="(error, key) in erroresCargue"
                              :key="key"
                            >
                              <td>{{ error }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- ARBOL DE DATOS -->
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div
                        class="col-md-12"
                        v-for="(dia, dia_index) in dataTreeProgramacion"
                        :key="dia_index"
                      >
                        <div
                          class="card card-secondary card-outline collapsed-card"
                        >
                          <div class="card-header pt-2 pb-2">
                            <h3 class="card-title">
                              <i class="fas fa-folder"></i>
                              Ventas FEC
                            </h3>
                            <div class="card-tools">
                              <button
                                type="button"
                                class="btn btn-tool"
                                data-card-widget="collapse"
                                data-toggle="tooltip"
                                title="Collapse"
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                          <div class="card-body pt-2">
                            <div class="row">
                              <div class="col-sm-12">
                                <nav>
                                  <ul
                                    data-widget="treeview"
                                    role="menu"
                                    data-accordion="false"
                                    class="nav nav-pills nav-sidebar flex-column nav-flat nav-child-indent"
                                    v-for="(origen, origen_index) in dia.data"
                                    :key="origen_index"
                                  >
                                    <li class="nav-item has-treeview">
                                      <a href="#" class="nav-link text-dark">
                                        <i class="nav-icon fas fa-circle"></i>
                                        <p style="font-size: 15px">
                                          {{ origen.nombre }} |
                                          <span class="badge">
                                            Promedio Cargue:
                                            {{
                                              form.nominacion.tipo_producto == 1
                                                ? origen.promedio_cargue
                                                : origen.prom_cargue_planta
                                            }}
                                          </span>
                                          |
                                          <span
                                            class="badge"
                                            :id="
                                              'vol_' +
                                                origen.id +
                                                '_' +
                                                dia.fecha
                                            "
                                          ></span>
                                          |
                                          <span
                                            class="badge"
                                            :id="
                                              'cargues_dec_' +
                                                origen.id +
                                                '_' +
                                                dia.fecha
                                            "
                                          ></span>
                                          |
                                          <span
                                            class="badge"
                                            :id="
                                              'cargues_' +
                                                origen.id +
                                                '_' +
                                                dia.fecha
                                            "
                                          ></span>
                                          |
                                          <span
                                            class="badge"
                                            :id="
                                              'volAdd_' +
                                                origen.id +
                                                '_' +
                                                dia.fecha
                                            "
                                          ></span>
                                          |
                                          <span
                                            class="badge"
                                            :id="
                                              'cargAdd_' +
                                                origen.id +
                                                '_' +
                                                dia.fecha
                                            "
                                          ></span>
                                          |
                                          <span
                                            class="badge"
                                            :id="
                                              'volDism_' +
                                                origen.id +
                                                '_' +
                                                dia.fecha
                                            "
                                          ></span>
                                          |
                                          <span
                                            class="badge"
                                            :id="
                                              'cargDism_' +
                                                origen.id +
                                                '_' +
                                                dia.fecha
                                            "
                                          ></span>
                                          <i
                                            class="right fas fa-angle-left"
                                          ></i>
                                        </p>
                                      </a>
                                      <ul
                                        class="nav nav-treeview border border-dark mt-2"
                                        v-for="(producto,
                                        producto_index) in origen.productos_liquidos"
                                        :key="producto_index"
                                      >
                                        <li class="nav-item">
                                          <div class="row ml-4 mr-4">
                                            <h5>{{ producto.nombre }}</h5>
                                            <div class="col-md-12">
                                              <table
                                                class="table table-sm table-striped table-bordered"
                                              >
                                                <thead
                                                  class="bg-navy text-center"
                                                >
                                                  <tr>
                                                    <th rowspan="2">Destino</th>
                                                    <th rowspan="2">Volumen</th>
                                                    <th colspan="4">Cargues</th>
                                                  </tr>
                                                  <tr>
                                                    <th>Auto</th>
                                                    <th>Ajuste</th>
                                                    <th>Categoría Operación</th>
                                                    <th>Diferencia</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr
                                                    v-for="(destino,
                                                    destino_index) in producto.destinos"
                                                    :key="destino_index"
                                                  >
                                                    <td>
                                                      {{ destino.nombre }}
                                                    </td>
                                                    <td>
                                                      <div class="row">
                                                        <div class="col-6">
                                                          <span
                                                            :id="
                                                              'volumen_' +
                                                                producto.id +
                                                                '_' +
                                                                origen.id +
                                                                '_' +
                                                                destino.id +
                                                                '_' +
                                                                dia.fecha
                                                            "
                                                          ></span>
                                                        </div>
                                                        <div class="col-3">
                                                          <button
                                                            type="button"
                                                            class="btn btn-sm bg-navy px-1 py-0 float-left"
                                                            data-toggle="modal"
                                                            data-target="#Modal-volumen-anterior"
                                                            @click="
                                                              getVolumenAnterior(
                                                                producto.id,
                                                                origen.id,
                                                                destino.id,
                                                                dia.fecha,
                                                                $route.params.id
                                                              )
                                                            "
                                                          >
                                                            <i
                                                              class="fas fa-history"
                                                            >
                                                            </i>
                                                          </button>
                                                        </div>
                                                        <div class="col-3">
                                                          <button
                                                            :disabled="
                                                              form.estado == 2
                                                            "
                                                            type="button"
                                                            class="btn btn-sm bg-navy px-1 py-0 float-left"
                                                            data-toggle="modal"
                                                            data-target="#Modal_volumenes"
                                                            v-if="
                                                              $store.getters.can(
                                                                'hidrocarburos.programacion.edit'
                                                              )
                                                            "
                                                            @click="
                                                              cargarDatos(
                                                                producto.id,
                                                                origen.id,
                                                                destino.id,
                                                                dia.fecha,
                                                                form.nominacion
                                                                  .tipo_producto ==
                                                                  1
                                                                  ? origen.promedio_cargue
                                                                  : origen.prom_cargue_planta,
                                                                $route.params
                                                                  .id,
                                                                programacion.nominacion_id
                                                              )
                                                            "
                                                          >
                                                            <i
                                                              class="fas fa-edit"
                                                            ></i>
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td
                                                      :id="
                                                        'cargues_dec_' +
                                                          producto.id +
                                                          '_' +
                                                          origen.id +
                                                          '_' +
                                                          destino.id +
                                                          '_' +
                                                          dia.fecha
                                                      "
                                                    ></td>
                                                    <td>
                                                      <div class="row">
                                                        <div class="col-md-3">
                                                          <input
                                                            :disabled="
                                                              form.estado == 2
                                                            "
                                                            type="number"
                                                            class="form-control form-control-sm"
                                                            :id="
                                                              'cargues_' +
                                                                producto.id +
                                                                '_' +
                                                                origen.id +
                                                                '_' +
                                                                destino.id +
                                                                '_' +
                                                                dia.fecha
                                                            "
                                                            v-model="
                                                              det_programacionesForm[
                                                                'cargues_' +
                                                                  producto.id +
                                                                  '_' +
                                                                  origen.id +
                                                                  '_' +
                                                                  destino.id +
                                                                  '_' +
                                                                  dia.fecha
                                                              ]
                                                            "
                                                            @keyup="
                                                              calcAjuste(
                                                                producto.id,
                                                                origen.id,
                                                                destino.id,
                                                                dia.fecha
                                                              )
                                                            "
                                                            @change="
                                                              calcAjuste(
                                                                producto.id,
                                                                origen.id,
                                                                destino.id,
                                                                dia.fecha
                                                              )
                                                            "
                                                          />
                                                        </div>
                                                        <div
                                                          class="col-md-8"
                                                          v-show="
                                                            det_programacionesForm[
                                                              'justificacion_ajuste_' +
                                                                producto.id +
                                                                '_' +
                                                                origen.id +
                                                                '_' +
                                                                destino.id +
                                                                '_' +
                                                                dia.fecha
                                                            ] !== '' &&
                                                              det_programacionesForm[
                                                                'justificacion_ajuste_' +
                                                                  producto.id +
                                                                  '_' +
                                                                  origen.id +
                                                                  '_' +
                                                                  destino.id +
                                                                  '_' +
                                                                  dia.fecha
                                                              ] !== null &&
                                                              det_programacionesForm[
                                                                'justificacion_ajuste_' +
                                                                  producto.id +
                                                                  '_' +
                                                                  origen.id +
                                                                  '_' +
                                                                  destino.id +
                                                                  '_' +
                                                                  dia.fecha
                                                              ] !== undefined
                                                          "
                                                        >
                                                          <input
                                                            :disabled="
                                                              form.estado == 2
                                                            "
                                                            type="text"
                                                            class="form-control form-control-sm"
                                                            placeholder="Justificacion"
                                                            :id="
                                                              'justificacion_ajuste_' +
                                                                producto.id +
                                                                '_' +
                                                                origen.id +
                                                                '_' +
                                                                destino.id +
                                                                '_' +
                                                                dia.fecha
                                                            "
                                                            v-model="
                                                              det_programacionesForm[
                                                                'justificacion_ajuste_' +
                                                                  producto.id +
                                                                  '_' +
                                                                  origen.id +
                                                                  '_' +
                                                                  destino.id +
                                                                  '_' +
                                                                  dia.fecha
                                                              ]
                                                            "
                                                          />
                                                        </div>
                                                        <div class="col-1">
                                                          <button
                                                            :disabled="
                                                              form.estado ==
                                                                2 ||
                                                                (!det_programacionesForm[
                                                                  'categoria_operacion_' +
                                                                    producto.id +
                                                                    '_' +
                                                                    origen.id +
                                                                    '_' +
                                                                    destino.id +
                                                                    '_' +
                                                                    dia.fecha
                                                                ] &&
                                                                  !det_programacionesForm[
                                                                    'justificacion_ajuste_' +
                                                                      producto.id +
                                                                      '_' +
                                                                      origen.id +
                                                                      '_' +
                                                                      destino.id +
                                                                      '_' +
                                                                      dia.fecha
                                                                  ])
                                                            "
                                                            type="button"
                                                            class="btn btn-success brn-sm px-1 py-0 float-right"
                                                            @click="
                                                              saveDetProgramacion(
                                                                producto.id,
                                                                origen.id,
                                                                destino.id,
                                                                dia.fecha
                                                              )
                                                            "
                                                            v-if="
                                                              $store.getters.can(
                                                                'hidrocarburos.programacion.editDetail'
                                                              )
                                                            "
                                                          >
                                                            <i
                                                              class="fas fa-save"
                                                            ></i>
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <select
                                                        placeholder="Tipo Operación"
                                                        class="form-control form-control-sm"
                                                        v-model="
                                                          det_programacionesForm[
                                                            'categoria_operacion_' +
                                                              producto.id +
                                                              '_' +
                                                              origen.id +
                                                              '_' +
                                                              destino.id +
                                                              '_' +
                                                              dia.fecha
                                                          ]
                                                        "
                                                      >
                                                        <option
                                                          v-for="tipoOp in tiposOperacion"
                                                          :key="
                                                            tipoOp.numeracion
                                                          "
                                                          :value="
                                                            tipoOp.numeracion
                                                          "
                                                        >
                                                          {{
                                                            tipoOp.descripcion
                                                          }}
                                                        </option>
                                                      </select>
                                                    </td>
                                                    <td
                                                      :id="
                                                        'cargues_dif_' +
                                                          producto.id +
                                                          '_' +
                                                          origen.id +
                                                          '_' +
                                                          destino.id +
                                                          '_' +
                                                          dia.fecha
                                                      "
                                                    ></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </nav>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-12"
                        v-if="
                          form.nominacion && form.nominacion.tipo_producto == 1
                        "
                      >
                        <div
                          class="card card-secondary card-outline collapsed-card"
                        >
                          <div class="card-header pt-2 pb-2">
                            <h3 class="card-title">
                              <i class="fas fa-folder"></i>
                              Ventas a terceros sin transporte
                              (Comercialización)
                            </h3>
                            <div class="card-tools">
                              <button
                                type="button"
                                class="btn btn-tool"
                                data-card-widget="collapse"
                                data-toggle="tooltip"
                                title="Collapse"
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                          <div class="card-body pt-2">
                            <div class="row">
                              <div class="col-sm-12">
                                <nav>
                                  <ul
                                    data-widget="treeview"
                                    role="menu"
                                    data-accordion="false"
                                    class="nav nav-pills nav-sidebar flex-column nav-flat nav-child-indent"
                                    v-for="oferta in dataTreeProgramacionTerceros"
                                    :key="oferta.fecha"
                                  >
                                    <div
                                      class="card card-navy card-outline collapsed-card mt-1"
                                    >
                                      <div class="card-header pt-2 pb-2">
                                        <h3 class="card-title">
                                          <i
                                            class="fas fa-map-marker-alt text-danger"
                                          ></i>
                                          {{ oferta.sitio.nombre }}
                                        </h3>
                                        <div class="card-tools">
                                          <button
                                            type="button"
                                            class="btn btn-tool"
                                            data-card-widget="collapse"
                                            data-toggle="tooltip"
                                            title="Collapse"
                                          >
                                            <i class="fas fa-plus"></i>
                                          </button>
                                        </div>
                                      </div>
                                      <div class="card-body pt-2">
                                        <table
                                          class="table table-bordered table-striped table-hover table-sm"
                                        >
                                          <thead class="bg-navy">
                                            <tr>
                                              <th class="text-center">API</th>
                                              <th class="text-center">
                                                Azufre
                                              </th>
                                              <th class="text-center">
                                                Volumen
                                              </th>
                                              <th class="text-center">
                                                Cant. Vehíc.
                                              </th>
                                              <th class="text-center">Fecha</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>{{ oferta.api }}</td>
                                              <td>{{ oferta.s }}</td>
                                              <td>{{ oferta.volumen }}</td>
                                              <td>{{ oferta.cant_vh }}</td>
                                              <td>{{ form.fecha_inicial }}</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </ul>
                                </nav>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header pt-2 pb-2">
                    <h3 class="card-title">
                      <i class="far fa-folder"></i>
                      Resumen
                    </h3>
                    <div class="card-tools">
                      <button
                        type="button"
                        class="btn btn-tool"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                  <div class="card-body">
                    <table class="table table-sm table-hover table-stripped table-bordered text-center">
                      <thead>
                        <tr>
                          <td rowspan="2">Fecha</td>
                          <td rowspan="2">Origen</td>
                          <td rowspan="2">Producto</td>
                          <td rowspan="2">Destino</td>
                          <td rowspan="2">Volumen</td>
                          <td colspan="3">Cargues</td>
                        </tr>
                        <tr>
                          <td>Automatico</td>
                          <td>Ajuste</td>
                          <td>Diferencia</td>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </section>

        <!-- Modal cambio de volúmenes -->
        <div class="modal fade" id="Modal_volumenes">
          <div class="modal-dialog modal-md">
            <div class="modal-content">
              <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                <h4 class="modal-title text-white">Modificar Volumen</h4>
                <button
                  type="button"
                  class="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="close-modal"
                  ref="closeModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <!-- Cuerpo del modal -->
              <div class="modal-body">
                <div class="row">
                  <div class="form-group col-md-12">
                    <label>Nuevo Volumen</label>
                    <input
                      :disabled="form.estado == 2"
                      type="number"
                      min="1"
                      class="form-control form-control-sm"
                      v-model="form_volumen.volumen_nuevo"
                      :class="
                        $v.form_volumen.volumen_nuevo.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div class="form-group col-md-12">
                    <label>Justificación</label>
                    <textarea
                      :disabled="form.estado == 2"
                      class="form-control form-control-sm"
                      v-model="form_volumen.justificacion"
                      :class="
                        $v.form_volumen.justificacion.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="modal-footer justify-content-between">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  :disabled="form.estado == 2"
                  type="button"
                  class="btn btn-primary"
                  v-if="
                    $store.getters.can('hidrocarburos.programacion.edit') &&
                      !$v.form_volumen.$invalid
                  "
                  @click="saveVolumenes()"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal volumen anterior -->
        <div
          class="modal fade"
          id="Modal-volumen-anterior"
          style="background-color: #00000082"
        >
          <div class="modal-dialog modal-md">
            <div class="modal-content">
              <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                <h4 class="modal-title text-white">Histórico de volúmenes</h4>
                <button
                  type="button"
                  class="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="close-modal"
                  ref="closeModal1"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" v-if="array_vol_anterior.length > 0">
                <table
                  class="table table-sm table-striped table-hover text-center"
                >
                  <thead class="thead-dark">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Fecha Creación</th>
                      <th scope="col">Fecha Actualización</th>
                      <th scope="col">Volumen Nuevo</th>
                      <th scope="col">Volumen Anterior</th>
                      <th scope="col">Justificación</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(anterior, index) in array_vol_anterior"
                      :key="anterior.id"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ anterior.created_at }}</td>
                      <td>{{ anterior.updated_at }}</td>
                      <td>{{ anterior.volumen_nuevo }}</td>
                      <td>{{ anterior.volumen_anterior }}</td>
                      <td class="text-left">
                        <small>{{ anterior.justificacion }}</small>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="modal-body" v-else>
                <i> No hay volumenes anteriores </i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import vSelect from "vue-select";
import { required, minLength } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";

export default {
  name: "ProgramacionEdit",
  components: {
    vSelect,
    Loading,
  },
  data() {
    return {
      form: {},
      array_vol_anterior: [],
      id_programacion: null,
      form_volumen: {
        det_programacion_id: null,
        volumen_anterior: null,
        volumen_nuevo: null,
        justificacion: null,
        promedio_cargue: null,
      },
      formdet: {},
      fileImport: false,
      cargando: false,
      nominacionesOption: [],
      detProgramacion: [],
      detProgramacionSitio: [],
      det_programacionesForm: {},
      det_programacionesSitiosForm: {},
      estados: [],
      dataTreeProgramacion: [],
      dataTreeProgramacionTerceros: [],
      erroresCargue: [],
      tipo_producto_nominacion: "",
      tiposOperacion: [],
    };
  },
  async beforeMount() {
    this.actGetListas(28).then(() => {
      this.estados = this.listas;
    });

    this.actGetListas(182).then(() => {
      //this.tiposOperacion = this.listas;
      this.listas.forEach((element) => {
        if (
          element.numeracion != 2 &&
          element.numeracion != 4 &&
          element.numeracion != 6
        ) {
          this.tiposOperacion.push(element);
        }
      });
    });

    await this.actGetNominacionesListFecha().then(() => {
      this.nominacionesListFecha.forEach((row) => {
        if (row.estado == 2) {
          this.nominacionesOption.push({
            id: row.id,
            nombre: row.nombre,
            tipo_producto: row.tipo_producto,
          });
        }
      });
    });

    await this.loadInfo();

    this.actGetTiposVehiculos();
    this.actGetProductos();
    //this.getTiposOperacion();
  },
  validations: {
    form: {
      fecha_inicial: {
        required,
      },
      fecha_final: {
        required,
      },
      estado: {
        required,
      },
      nominacion: {
        id: {
          required,
        },
      },
    },
    form_volumen: {
      det_programacion_id: {
        required,
      },
      volumen_nuevo: {
        required,
      },
      justificacion: {
        required,
        minLength: minLength(10),
      },
    },
  },
  computed: {
    ...mapState("modOperacion", ["listas"]),
    ...mapState("modHidrocarburos", [
      "loading",
      "nominacionesListFecha",
      "programacion",
      "treeProgramacion",
    ]),
  },
  methods: {
    ...mapActions("modOperacion", ["actGetListas"]),
    ...mapActions("modHidrocarburos", [
      "actSaveProgramacion",
      "actGetNominacionesListFecha",
      "actGetProductos",
      "actGetTiposVehiculos",
      "actGetProgramacion",
      "actCloseProgramacion",
      "actGetTreeProgramacion",
    ]),

    async loadInfo() {
      this.cargando = true;
      await this.actGetProgramacion(this.$route.params.id).then(() => {
        this.form = this.programacion;
        this.detProgramacion = this.programacion.det_programaciones;
        this.detProgramacionSitio = this.programacion.det_programaciones_sitios;

        this.form.nominacion = this.nominacionesOption.find(
          (n) => n.id === this.programacion.nominacion_id
        );
      });

      await this.actGetTreeProgramacion(this.form.id).then(() => {
        /* console.log(this.treeProgramacion.result_dias); */
        this.dataTreeProgramacion = this.treeProgramacion.result_dias;
        this.dataTreeProgramacionTerceros = this.treeProgramacion.ofertas_comercial;
      });

      await this.buldArrFormDetProgramacion();
      this.cargando = false;
    },

    validaFechas() {
      if (this.form.fecha_inicial && this.form.fecha_final) {
        const fecha_menor = new Date(this.form.fecha_inicial);
        const fecha_mayor = new Date(this.form.fecha_final);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    closeProgramacion() {
      if (this.form.estado == 2) {
        this.$swal({
          title: "Esta seguro de poner en estado definitivo la Programación?",
          text: "No podra volver a editar la Programación",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Definitivo",
        }).then((result) => {
          // <--
          if (result.value) {
            this.saveProgramacion();
          }
        });
      }
    },

    saveProgramacion() {
      let data = [];
      data = {
        nominacion_id: this.form.nominacion.id,
        fecha_inicial: this.form.fecha_inicial,
        fecha_final: this.form.fecha_final,
        observacion: this.form.observacion,
        estado: this.form.estado,
        id: this.form.id,
      };
      //alert(JSON.stringify(data));
      this.actSaveProgramacion(data).then((response) => {
        if (response.id) {
          this.form.id = response.id;

          this.$swal({
            icon: "success",
            title: "La programación base se creó con exito",
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
        }
      });
    },

    downloadWithAxios() {
      this.cargando = true;
      axios({
        method: "get",
        url: "/api/hidrocarburos/programacion/export/" + this.form.id,
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    elegirFile() {
      this.fileImport = this.$refs.fileImport.files[0];
    },

    async importarProgramacion() {
      this.cargando = true;
      let formData = new FormData();
      formData.append("file", this.fileImport);
      formData.append("programacion_id", this.form.id);
      await axios
        .post(
          "/api/hidrocarburos/programacion/import/" + this.form.id,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(async (response) => {
          if (response.data.errores.length > 0) {
            this.erroresCargue = response.data.errores;
          }
          let inputImage = document.getElementById("fileImport");
          inputImage.value = "";
          this.fileImport = false;
          await this.loadInfo();
          this.$swal({
            icon: "success",
            title: response.data.mensaje,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    buldArrFormDetProgramacion() {
      this.detProgramacion.forEach((DP) => {
        if (
          document.querySelector(
            "#volumen_" +
              DP.producto_id +
              "_" +
              DP.origen_id +
              "_" +
              DP.destino_id +
              "_" +
              DP.fecha
          )
        ) {
          this.det_programacionesForm[
            "data_" +
              DP.producto_id +
              "_" +
              DP.origen_id +
              "_" +
              DP.destino_id +
              "_" +
              DP.fecha
          ] = DP;

          this.det_programacionesForm[
            "cargues_" +
              DP.producto_id +
              "_" +
              DP.origen_id +
              "_" +
              DP.destino_id +
              "_" +
              DP.fecha
          ] = DP.cargues;

          this.det_programacionesForm[
            "cargues_dec_" +
              DP.producto_id +
              "_" +
              DP.origen_id +
              "_" +
              DP.destino_id +
              "_" +
              DP.fecha
          ] = DP.cargues_dec;

          this.det_programacionesForm[
            "cargues_dif_" +
              DP.producto_id +
              "_" +
              DP.origen_id +
              "_" +
              DP.destino_id +
              "_" +
              DP.fecha
          ] = DP.cargues - DP.cargues_dec;

          this.det_programacionesForm[
            "justificacion_ajuste_" +
              DP.producto_id +
              "_" +
              DP.origen_id +
              "_" +
              DP.destino_id +
              "_" +
              DP.fecha
          ] = DP.justificacion_ajuste;

          if (
            DP.justificacion_ajuste !== null &&
            DP.justificacion_ajuste !== ""
          ) {
            document.querySelector(
              "#justificacion_ajuste_" +
                DP.producto_id +
                "_" +
                DP.origen_id +
                "_" +
                DP.destino_id +
                "_" +
                DP.fecha
            ).style.display = "block";

            document.querySelector(
              "#justificacion_ajuste_" +
                DP.producto_id +
                "_" +
                DP.origen_id +
                "_" +
                DP.destino_id +
                "_" +
                DP.fecha
            ).value = DP.justificacion_ajuste;
          }

          this.det_programacionesForm[
            "volumen_" +
              DP.producto_id +
              "_" +
              DP.origen_id +
              "_" +
              DP.destino_id +
              "_" +
              DP.fecha
          ] = DP.volumen;

          document.querySelector(
            "#volumen_" +
              DP.producto_id +
              "_" +
              DP.origen_id +
              "_" +
              DP.destino_id +
              "_" +
              DP.fecha
          ).innerHTML = DP.volumen;

          document.querySelector(
            "#cargues_dec_" +
              DP.producto_id +
              "_" +
              DP.origen_id +
              "_" +
              DP.destino_id +
              "_" +
              DP.fecha
          ).innerHTML = DP.cargues_dec;

          document.querySelector(
            "#cargues_dif_" +
              DP.producto_id +
              "_" +
              DP.origen_id +
              "_" +
              DP.destino_id +
              "_" +
              DP.fecha
          ).innerHTML = DP.cargues - DP.cargues_dec;

          document.querySelector(
            "#cargues_" +
              DP.producto_id +
              "_" +
              DP.origen_id +
              "_" +
              DP.destino_id +
              "_" +
              DP.fecha
          ).value = DP.cargues;

          this.det_programacionesForm[
            "categoria_operacion_" +
              DP.producto_id +
              "_" +
              DP.origen_id +
              "_" +
              DP.destino_id +
              "_" +
              DP.fecha
          ] = DP.categoria_operacion;
        }
      });

      this.detProgramacionSitio.forEach((DPS) => {
        this.det_programacionesSitiosForm[DPS.sitio_id + "_" + DPS.fecha] = DPS;

        document.querySelector(
          "#vol_" + DPS.sitio_id + "_" + DPS.fecha
        ).innerHTML = " Volumen: " + DPS.volumen;

        document.querySelector(
          "#cargues_dec_" + DPS.sitio_id + "_" + DPS.fecha
        ).innerHTML = " Cargues: " + DPS.cargues_dec;

        document.querySelector(
          "#cargues_" + DPS.sitio_id + "_" + DPS.fecha
        ).innerHTML = " Cargues Ajuste: " + DPS.cargues; //aca /Det programacionSitio

        document.querySelector(
          "#volAdd_" + DPS.sitio_id + "_" + DPS.fecha
        ).innerHTML = " Vol. Adicional: " + DPS.vol_adicional;

        document.querySelector(
          "#cargAdd_" + DPS.sitio_id + "_" + DPS.fecha
        ).innerHTML = " Carg. Adicional: " + DPS.cargues_adicional;

        document.querySelector(
          "#volDism_" + DPS.sitio_id + "_" + DPS.fecha
        ).innerHTML = " Vol. Disminucion: " + DPS.vol_disminucion;

        document.querySelector(
          "#cargDism_" + DPS.sitio_id + "_" + DPS.fecha
        ).innerHTML = "Carg. Disminucion: " + DPS.cargues_disminucion;
      });
    },

    calcAjuste(producto, origen, destino, fecha) {
      let carg_dec = this.det_programacionesForm[
        "cargues_dec_" + producto + "_" + origen + "_" + destino + "_" + fecha
      ];

      let valor = this.det_programacionesForm[
        "cargues_" + producto + "_" + origen + "_" + destino + "_" + fecha
      ];

      let lim_inf = Math.floor(carg_dec);
      let lim_sup = Math.ceil(carg_dec);

      if (valor > lim_sup || valor < lim_inf) {
        this.$swal({
          icon: "error",
          title: "No puede ajustar este valor excede los limites permitidos",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.det_programacionesForm[
          "cargues_" + producto + "_" + origen + "_" + destino + "_" + fecha
        ] = Math.round(carg_dec);

        document.querySelector(
          "#cargues_" + producto + "_" + origen + "_" + destino + "_" + fecha
        ).value = Math.round(carg_dec);
      }
    },

    saveDetProgramacion(producto, origen, destino, fecha) {
      this.cargando = true;

      let categoria_operacion = this.det_programacionesForm[
        "categoria_operacion_" +
          producto +
          "_" +
          origen +
          "_" +
          destino +
          "_" +
          fecha
      ];

      let carg_dec = this.det_programacionesForm[
        "cargues_dec_" + producto + "_" + origen + "_" + destino + "_" + fecha
      ];

      let valor = this.det_programacionesForm[
        "cargues_" + producto + "_" + origen + "_" + destino + "_" + fecha
      ];

      let lim_inf = Math.floor(carg_dec);
      let lim_sup = Math.ceil(carg_dec);

      let justificacion = this.det_programacionesForm[
        "justificacion_ajuste_" +
          producto +
          "_" +
          origen +
          "_" +
          destino +
          "_" +
          fecha
      ];

      let data = this.det_programacionesForm[
        "data_" + producto + "_" + origen + "_" + destino + "_" + fecha
      ];

      if (valor > lim_sup || valor < lim_inf) {
        if (
          justificacion == "" ||
          justificacion == "Ingrese justificacion" ||
          justificacion.length < 20
        ) {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title:
              "Debe ingresar una justificacion valida. Minimo 20 caracteres",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          return false;
        }
      }

      let cargues = this.det_programacionesForm[
        "cargues_" + producto + "_" + origen + "_" + destino + "_" + fecha
      ];
      let datos = {
        justificacion_ajuste: justificacion,
        cargues: cargues,
        datos: data,
        categoria_operacion: categoria_operacion,
      };
      axios({
        method: "PATCH",
        url: "/api/hidrocarburos/programacion/detail/" + data.id,
        data: datos,
      }).then((res) => {
        document.querySelector(
          "#cargues_" + res.data[1].sitio_id + "_" + res.data[1].fecha
        ).innerHTML = " Cargues Ajuste: " + res.data[1].cargues; //aca /Det programacionSitio

        document.querySelector(
          "#cargAdd_" + res.data[1].sitio_id + "_" + res.data[1].fecha
        ).innerHTML = " Carg. Adicional: " + res.data[1].cargues_adicional; //aca /Det programacionSitio

        document.querySelector(
          "#cargDism_" + res.data[1].sitio_id + "_" + res.data[1].fecha
        ).innerHTML = " Carg. Disminucion: " + res.data[1].cargues_disminucion; //aca /Det programacionSitio

        document.querySelector(
          "#cargues_dif_" +
            producto +
            "_" +
            origen +
            "_" +
            destino +
            "_" +
            fecha
        ).innerHTML = res.data[0].cargues - res.data[0].cargues_dec;

        this.det_programacionesForm[
          "cargues_dif_" + producto + "_" + origen + "_" + destino + "_" + fecha
        ] = res.data[0].cargues - res.data[0].cargues_dec;

        this.cargando = false;
        this.$swal({
          icon: "success",
          title: "Datos actualizados con exito",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      });
    },

    cargarDatos(
      producto_id,
      origen_id,
      destino_id,
      fecha,
      promedio_cargue,
      programacion_id,
      nominacion_id
    ) {
      this.form_volumen = {
        det_programacion_id: null,
        volumen_anterior: null,
        volumen_nuevo: null,
        justificacion: null,
        promedio_cargue: null,
      };

      const datos = this.det_programacionesForm[
        "data_" + producto_id + "_" + origen_id + "_" + destino_id + "_" + fecha
      ];
      const sitio = this.det_programacionesSitiosForm[origen_id + "_" + fecha];

      this.form_volumen.det_programacion_id = datos ? datos.id : null;
      this.form_volumen.volumen_anterior = datos ? datos.volumen : null;
      this.form_volumen.volumen_nuevo = datos ? datos.volumen : null;
      this.form_volumen.promedio_cargue = promedio_cargue;
      this.form_volumen.origen_id = origen_id;
      this.form_volumen.programacion_id = programacion_id;
      this.form_volumen.det_programacion_sitio_id = datos ? sitio.id : null;
      this.form_volumen.nominacion_id = nominacion_id;
    },

    getVolumenAnterior(
      producto_id,
      origen_id,
      destino_id,
      fecha,
      programacion_id
    ) {
      this.array_vol_anterior = [];
      this.form_volumen = {
        det_programacion_id: null,
      };
      const datos = this.det_programacionesForm[
        "data_" + producto_id + "_" + origen_id + "_" + destino_id + "_" + fecha
      ];
      const sitio = this.det_programacionesSitiosForm[origen_id + "_" + fecha];
      this.form_volumen.det_programacion_id = datos.id;

      axios({
        method: "GET",
        url: "/api/hidrocarburos/programacion/volAnterior/dato",
        params: {
          id_programacion: this.form_volumen.det_programacion_id,
        },
      }).then((response) => {
        this.array_vol_anterior = response.data;
      });
    },

    saveVolumenes() {
      if (!this.$v.form_volumen.justificacion.$invalid) {
        this.cargando = true;
        axios({
          method: "PUT",
          url: "/api/hidrocarburos/programacion/modificarVolumen",
          data: this.form_volumen,
        })
          .then(() => {
            this.loadInfo();
            this.$refs.closeModal.click();
            this.$swal({
              icon: "success",
              title: `Se ha modificado el volumen con éxito...`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch(() => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ha ocurrido un error, intentelo nuevamente",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

  },
};
</script>
